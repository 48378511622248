//@ts-ignore
if (window.location.pathname === '/login') {
  import(/* webpackChunkName: "unauthorized" */ './unauthorized').catch(
    console.error,
  )
} else {
  import(/* webpackChunkName: "authorized" */ './authorized').catch(
    console.error,
  )
}
